<template>
  <tr>
    <td class='text-left'>{{result.ipo_summary.subscription_date}}</td>
    <td class='text-left'>{{result.ipo_summary.lockup_expiration_date}}</td>
    <td class='text-left'>{{result.fund_short_name}}</td>
    <td class='text-left'>{{strategy}}</td>
    <td class='text-left'>A{{selectedIpo.share_code}}</td>
    <td class='text-left'></td>
    <td class='text-left'>{{book}}</td>
    <td class='text-left'>{{limit}}</td>
    <td class='text-left'>L</td>
    <td class='text-right'>{{numberStyle(result.new_allocated_quantity)}}</td>
    <td class='text-left'>{{currentUserInfo.userName}}</td>
    <td class='text-left'>공모주 {{memo}}</td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IpoResultLockupRow',
  props: [
    'result',
    'book',
  ],
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    ...mapState('users', [
      'currentUserInfo'
    ]),
    strategy () {
      return this.book.toUpperCase() === 'CASH' ? 'Whole' : this.result.strategy
    },
    limit () {
      let is_new_shares = this.result.new_allocated_quantity > 0 ? true : false
      let value

      if (this.result.ipo_summary.is_lock_up) {
        value = is_new_shares ? 'PV' : 'P'
      }
      else if (this.result.ipo_summary.is_listing_transfer) {
        value = is_new_shares ? 'TV' : 'T'
      }
      else {
        value = is_new_shares ? 'V' : 'None'
      }

      return value
    },
    memo () {
      return this.result.ipo_summary.lock_up_period === '' || this.result.ipo_summary.lock_up_period === null ? '미확약' : this.result.ipo_summary.lock_up_period
    }
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang="scss" scoped>
  td {
    @apply tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
</style>
